import { Message } from 'element-ui'

/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */
export function createUniqueString(): string {
  const timestamp = +new Date() + ''
  const randomNum = parseInt(`${(1 + Math.random()) * 65536}`) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * 数字存储大小格式化
 * @param {number} num 存储大小 单位：Byte
 * @param {number} digits 保留几位小数
 * @return {string} 2MB
 */
export function toStorage(num: number, digits: number = 1): string {
  digits = digits || 2
  if (num < 1024) {
    return num + 'B'
  }
  num = (num * 1000) / 1024
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        `${parseInt((num / si[i].value).toFixed(digits))}`.replace(
          /\.0+$|(\.[0-9]*[1-9])0+$/,
          '$1'
        ) +
        si[i].symbol +
        'B'
      )
    }
  }
  return ''
}

/**
 * 图片上传
 * @param {file} file el-upload文件对象
 * @param {number} size 限制的文件大小(kb) 默认10M
 */
export const validImgUpload = (
  file: any,
  size: number,
  fileType: string = 'img'
) => {
  size = +size || 10240
  const isSizeOut = file.size / 1024 > size
  if (isSizeOut) {
    Message.error(
      `上传${fileType === 'img' ? '图片' : '视频'}大小不能超过` +
      toStorage(size * 1024)
    )
  }
  return !isSizeOut
}

export function addClass(elementName: string, className: string) {
  if (!elementName || !className) return
  const element = document.querySelector(elementName)
  if (!element) return

  let classVal = element.getAttribute('class')
  if (classVal) {
    classVal = classVal.concat(` ${className}`)
  } else {
    classVal = className
  }
  element.setAttribute('class', classVal)
}

export function removeClass(elementName: string, className: string) {
  if (!elementName || !className) return
  const element = document.querySelector(elementName)

  if (!element) return
  let classVal = element.getAttribute('class')
  if (!classVal) return
  classVal = classVal.replace(className, '')
  element.setAttribute('class', classVal)
}