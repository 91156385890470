
















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import PanelGroup from "@/components/PanelGroup.vue";
import Pagination from "@components/pagination/Pagination.vue";
import PopupPreviewVideo from "@components/PopupPreviewVideo.vue";
import { UserModule } from "@/store/modules/user";
import { ITabModel, IPanelData } from "@/data/commonModel";
import { IVideoModel, IVideoListQuery } from "./data/videoModel";
import {
  uploadVideo,
  deleteLandingVideo,
  getLandingVideoList,
  updateLandingVideoUseStatus,
  getVideo,
} from "@/remote/video";
import { validImgUpload } from "@/utils/tools";
import moment from "moment";
import { Form as ElForm } from "element-ui";

// 装饰器模式
@Component({
  components: {
    PanelGroup,
    PopupPreviewVideo,
    Pagination,
    ElForm,
  },
})
export default class extends Vue {
  // Data
  panelObj: IPanelData = { type: "all", searchText: "", show: true };
  isUploading: boolean = false;
  totalSize: number = 0;
  list: IVideoModel[] = [];
  listLoading: boolean = false;
  previewDialogVisible: boolean = false;
  curMediaPath: string = "";
  curMediaPathType: string = "video";
  uploadAction = `/api/v3/wineries/${UserModule.wineryId}/screenvideos/upload?isInternal=true`;
  headers = {
    Authorization: UserModule.accessToken,
  };
  listQuery: IVideoListQuery = {
    page: 1,
    size: 10,
    searchText: this.panelObj.searchText,
    createdTime: "",
  };
  formParams = {
    tencentMediaId: "",
  };
  rules = {
    videoId: {
      type: "string",
      required: true,
      trigger: "focus",
      message: "请输入视频Id",
    },
  };

  // Get
  get tabs(): ITabModel[] {
    const arr = [{ title: `全部(${this.totalSize})`, key: "all" }];
    return arr;
  }

  // Watch
  @Watch("panelObj.searchText")
  onSearchTextChange(val: string) {
    if (val.length === 0 && this.totalSize === 0) return;
    this.listQuery.searchText = val;
    this._fetchVideoList();
  }

  // Methods
  mounted() {
    this._fetchVideoList();
  }

  async _fetchVideoList() {
    this.listLoading = true;
    try {
      const res = await getLandingVideoList(this.listQuery);
      if (res && res.data && res.data.data) {
        const { total, data } = res.data.data;
        data.forEach((element: IVideoModel) => {
          element.createTime = moment(element.createdAt).format(
            "YYYY-MM-DD HH:mm"
          );
          element.isVideo = element.format.indexOf("image") === -1;
        });
        this.totalSize = total;
        this.list = data;
      }
    } catch {
      // console.log("getLandingVideoList 出错了");
    }
    this.listLoading = false;
  }

  _handleSwitchTab(props: IPanelData) {
    this.panelObj = props;
  }

  _beforeUpload(file: any) {
    if (validImgUpload(file, 10240, "video")) {
      this.isUploading = true;
      return true;
    }
    return false;
  }

  _onExceed() {
    this.isUploading = false;
    this.$message.error("视频大小必须小于10MB");
  }

  _onError(error: any) {
    // console.log("_onError:");
    // console.log(error);
    this.isUploading = false;
    this.$message.error("上传失败");
  }

  _onSuccessUpload(res: any) {
    this.isUploading = false;
    this.$message.success("上传成功");
    this._fetchVideoList();
  }

  _onClickConfirm() {
    const form = this.$refs["vedioForm"] as ElForm;
    form.validate((valid: Boolean) => {
      if (valid) {
        this._uploadVedio(this.formParams.tencentMediaId);
      }
    });
  }

  async _uploadVedio(tencentMediaId: string) {
    this.listLoading = true;
    try {
      const res = await uploadVideo(tencentMediaId);
      if (res.data.resultCode === 0) {
        this._fetchVideoList();
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败");
      }
      this.listLoading = false;
    } catch {
      this.listLoading = false;
      // console.log("deleteLandingVideo 出错了");
    }
  }
  videoUrl: string = "";
  async _onClickPreview(path: string, type: string) {
    console.log(path, type);
    if (type === "video") {
      const res = await getVideo(path);
      if (res?.data?.data?.url) {
        this.curMediaPath = res.data.data.url;
      } else {
        this.$message.error("请确认视频id是否正确");
        return;
      }
    } else {
      this.curMediaPath = path;
    }
    this.previewDialogVisible = true;
    // todo, the string need to be deleted if api is fixed

    this.curMediaPathType = type;
  }
  async _onClickDelete(id: string, index: number, isVideo: boolean) {
    this.$confirm(`确定删除该${isVideo ? "视频" : "图片"}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.listLoading = true;
        try {
          const res = await deleteLandingVideo(id);
          if (res.data.resultCode === 0) {
            if (this.list.length === 1 && this.listQuery.page > 1) {
              this.listQuery.page -= 1;
            }
            this._fetchVideoList();
            this.$message.success("操作成功");
          } else {
            this.$message.error("操作失败");
          }
          this.listLoading = false;
        } catch {
          this.listLoading = false;
          // console.log("deleteLandingVideo 出错了");
        }
      })
      .catch(() => {});
  }

  _onSortChange(column: any) {
    const { order, prop } = column;
    if (!order) {
      this.listQuery.createdTime = "";
    } else if (order === "descending") {
      this.listQuery.createdTime = "DESC";
    } else {
      this.listQuery.createdTime = "ASC";
    }
    this._fetchVideoList();
  }

  async _onChangeUseSwitch(value: boolean, id: string) {
    this.listLoading = true;
    try {
      const res = await updateLandingVideoUseStatus(id, value);
      if (res.data.resultCode === 0) {
        this._fetchVideoList();
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败");
      }
      this.listLoading = false;
    } catch {
      this.$message.error("操作失败");
      this.listLoading = false;
      // console.log("updateLandingVideoUseStatus 出错了");
    }
  }

  _updatePage(value: any) {
    this.listQuery.page = value.page;
    this._fetchVideoList();
  }
}
